import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import SEO from "@components/seo"

import { detectMob } from "@src/Helper"
import "./about.scss"

function About() {
  const images = useStaticQuery(graphql`
    {
      history: file(relativePath: { eq: "backgrounds/exm-history-2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      willyCeo: file(
        relativePath: { eq: "backgrounds/exm-willy-hansen-3.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      whoWeAre: file(relativePath: { eq: "backgrounds/exm-who-we-are-2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      willy: file(relativePath: { eq: "staff/willy.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      denny: file(relativePath: { eq: "staff/denny.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      suarti: file(relativePath: { eq: "staff/suarti.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      cc: file(relativePath: { eq: "staff/cc.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      stephen: file(relativePath: { eq: "staff/stephen.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      steve: file(relativePath: { eq: "staff/steve.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      meikelwis: file(relativePath: { eq: "staff/meikelwis.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      tedd: file(relativePath: { eq: "staff/tedd.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      vita: file(relativePath: { eq: "staff/vita.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      kenedy: file(relativePath: { eq: "staff/kenedy.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      rara: file(relativePath: { eq: "staff/rara.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      jimbobo: file(relativePath: { eq: "staff/jimbobo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      velia: file(relativePath: { eq: "staff/velia.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      wendy: file(relativePath: { eq: "staff/wendy.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      martin: file(relativePath: { eq: "staff/martin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      yosi: file(relativePath: { eq: "staff/yosi.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      destin: file(relativePath: { eq: "staff/destin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      fehrix: file(relativePath: { eq: "staff/fehrix.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      happy: file(relativePath: { eq: "staff/happy.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      nonop: file(relativePath: { eq: "staff/nonop.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      yongky: file(relativePath: { eq: "staff/yongky.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      trisna: file(relativePath: { eq: "staff/trisna.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      gilbert: file(relativePath: { eq: "staff/gilbert.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      alvian: file(relativePath: { eq: "staff/alvian.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const staff = [
    {
      name: "Willy Hansen",
      title: "CEO",
      image: images.willy.childImageSharp.fluid,
      socials: {
        instagram: "https://instagram.com/willyhsn",
        linkedin: "https://www.linkedin.com/in/willysf/",
      },
    },
    {
      name: "Yongky Ali",
      title: "CTO",
      image: images.yongky.childImageSharp.fluid,
      socials: {},
    },
    {
      name: "Denny Sutomo",
      title: "Comm",
      image: images.denny.childImageSharp.fluid,
      socials: {
        instagram: "https://instagram.com/dennysutomo_",
        linkedin: "https://www.linkedin.com/in/dennysutomo/",
      },
    },
    {
      name: "Stephen",
      title: "Software Developer",
      image: images.stephen.childImageSharp.fluid,
      socials: {
        instagram: "https://www.instagram.com/stephenxio/",
        linkedin: "https://www.linkedin.com/in/stephenhuang96/",
      },
    },
    {
      name: "Tiffany Diefang",
      title: "Illustrator",
      image: images.tedd.childImageSharp.fluid,
      socials: {
        instagram: "https://www.instagram.com/tedejrk/",
        linkedin: "https://www.linkedin.com/in/tiffany-diefang-7a890113b/",
      },
    },
    {
      name: "Christine",
      title: "UI/UX Designer",
      image: images.cc.childImageSharp.fluid,
      socials: {
        instagram: "https://instagram.com/ccdnkz",
        linkedin: "https://www.linkedin.com/in/christine-chandra-b7487419b/",
      },
    },
    {
      name: "Kenedy Lukito",
      title: "IT Supervisor",
      image: images.kenedy.childImageSharp.fluid,
      socials: {
        instagram: "https://www.instagram.com/kenedylukito/",
        linkedin: "https://www.linkedin.com/in/kenedy-lukito-5b9209b4/",
      },
    },
    {
      name: "Jimmy Tantriyadi",
      title: "Content Creator",
      image: images.jimbobo.childImageSharp.fluid,
      socials: {
        instagram: "https://instagram.com/jimjim.o",
        linkedin: "https://www.linkedin.com/in/jimmy-tantriyadi-3b818a191/",
      },
    },
    {
      name: "Meikelwis Wijaya",
      title: "Finance",
      image: images.meikelwis.childImageSharp.fluid,
      socials: {
        instagram: "https://instagram.com/mike.wisz",
        linkedin: "https://www.linkedin.com/in/meikelwis-wijaya-650419121/",
      },
    },
    {
      name: "Vita Harvi",
      title: "Cinematographer",
      image: images.vita.childImageSharp.fluid,
      socials: {
        instagram: "https://instagram.com/vitaharvi",
        linkedin: "https://www.linkedin.com/in/vita-harvi-9bb87619b",
      },
    },
    {
      name: "Yosi",
      title: "Customer Loyalty Staff",
      image: images.yosi.childImageSharp.fluid,
      socials: {
        instagram: "https://instagram.com/ctypsb",
        linkedin:
          "https://www.linkedin.com/in/christin-yosephin-sibuea-44334b10a/",
      },
    },
    {
      name: "Happy",
      title: "Customer Loyalty Staff",
      image: images.happy.childImageSharp.fluid,
      socials: {
        instagram: "https://www.instagram.com/khowilly_/",
        linkedin: "https://www.linkedin.com/in/willy-kho-619933183/",
      },
    },
    {
      name: "Trisna",
      title: "Customer Loyalty Staff",
      image: images.trisna.childImageSharp.fluid,
      socials: {
        instagram: "https://www.instagram.com/mhdtrisnadirgantara/",
        linkedin: "https://www.linkedin.com/in/muhammadtrisnadirgantara/",
      },
    },
    {
      name: "Gilbert Wijaya",
      title: "Software Engineer",
      image: images.gilbert.childImageSharp.fluid,
      socials: {
        instagram: "https://www.instagram.com/gwijaya94/",
        linkedin: "https://www.linkedin.com/in/gilbertwijaya94/",
      },
    },
    {
      name: "Alvian Onggo",
      title: "Software Engineer",
      image: images.alvian.childImageSharp.fluid,
      socials: {
        instagram: "https://www.instagram.com/alvianonggo/",
        linkedin: "https://www.linkedin.com/in/alvianonggo/",
      },
    },
  ]

  const [id, setId] = useState("about")

  useEffect(() => {
    if (detectMob()) {
      setId("m-about")
    }
  }, [])

  function renderSocials(socials) {
    let socialComponent = []

    if (socials.instagram) {
      socialComponent.push(
        <a href={socials.instagram} target="_blank" rel="noopener noreferrer">
          <div className="social-item">
            <i className="fa fa-instagram" />
          </div>
        </a>
      )
    }

    if (socials.linkedin) {
      socialComponent.push(
        <a href={socials.linkedin} target="_blank" rel="noopener noreferrer">
          <div className="social-item">
            <i className="fa fa-linkedin" />
          </div>
        </a>
      )
    }

    return socialComponent
  }

  return (
    <div id={id}>
      <SEO title="About" />
      <div className="banner about-banner">
        <div className="container">
          <div>
            We Are The Troupes Behind <br />A Creative Network
          </div>
        </div>
      </div>

      <section className="section-100">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 text-center text-md-left mb-5 mb-md-0">
              <div className="title text-center">HISTORY</div>
              <hr className="separator-mafia-primary" />
              <div className="subcaption">
                <p>
                  The idea of Executive Mafia was first created in 2016 by a
                  group of influencers, whose one of them is Jin
                  @successfoundation on Instagram. The thought behind the idea
                  came up after they received many questions about how to grow
                  an Instagram page.
                </p>
                <p>
                  They then decided to build a company—Executive Mafia—to help
                  people who want to become influencers and grow their pages.
                  With their combined methods of page-growing, Executive Mafia
                  officially started its journey in May 2017.
                </p>
              </div>

              <hr className="separator-mafia-primary" />
            </div>

            <div className="col-12 col-md-6">
              <div>
                <Img
                  style={{
                    borderRadius: 8,
                    boxShadow: "0px 4px 15px rgba(31, 78, 229, 0.17)",
                  }}
                  fluid={images.history.childImageSharp.fluid}
                  alt="EXM History"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-100">
        <div className="about-full-row row ml-0 mr-0">
          <div className="col-12 col-md-6 pl-0 p-relative pr-0 pr-md-3">
            <Img
              fluid={images.willyCeo.childImageSharp.fluid}
              alt="Co-founder Willy Hansen"
            />
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center pl-0">
            <div className="about-ceo-quote">
              <div className="pl-4 py-5">
                <p>
                  Lots of people asked me about the secret method I used to grow
                  past 900k followers, but the truth is, there's no special
                  method in particular. The only method I used is habit
                  building.
                </p>
                <p>
                  Executive Mafia was created with an ideal habit building
                  system to help influencers and brands grow through their
                  positive habits.
                </p>
                <p>
                  Now, as you can tell, building the right habit was my first
                  step in succeeding. What about you?
                </p>
                <div className="about-divider" />
                <div className="ceo-brand">
                  Willy Hansen, CEO of Executive Mafia
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-100">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 mb-5 mb-md-0">
              <Img
                style={{
                  borderRadius: 8,
                  boxShadow: "0px 4px 15px rgba(31, 78, 229, 0.17)",
                }}
                fluid={images.whoWeAre.childImageSharp.fluid}
                alt="Who we are at Executive Mafia"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-center">
              <div>
                <div className="title text-center">WHO WE ARE</div>
                <hr className="separator-mafia-primary" />
                <p className="subcaption text-center">
                  We are individuals with different personalities, different
                  characteristics, and different fields who met in the same
                  company where we were brought together with the will to create
                  an amazing impact through social media.
                </p>
                <hr className="separator-mafia-primary" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-100 about-everyone">
        <div className="about-staff text-pure-white text-center">
          <div className="container">
            <div className="title mb-5">MEET EVERY ONE OF US</div>
            <hr className="separator-mafia-white" />
            <div className="staff-grid">
              {staff.map((value, key) => {
                return (
                  <div key={key} className="staff-container">
                    <div className="staff-image">
                      {!detectMob() ? (
                        <Img style={{ height: "132px" }} fluid={value.image} />
                      ) : (
                        <Img fluid={value.image} />
                      )}
                    </div>
                    <div className="staff-name">{value.name}</div>
                    <div className="staff-social-group">
                      {renderSocials(value.socials)}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default About
